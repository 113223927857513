import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';

const Partnerships = ({ data }) => {
  const { partnerships } = data;

  return (
    <Layout bgcolor={partnerships.backgroundColor} wrapperClass="partnerships" accentColor={partnerships.accentColor}>
      <HelmetDatoCms seo={partnerships.seoMetaTags} />
      <div className="main-block">
        <div className="main-title">
          <h1 className="gothic--large large-title col">{partnerships.title}</h1>
        </div>
        <div className="main-image col">
          <Img fluid={partnerships.featuredImage.fluid} className="featured-title-image" />
          <p
            className="caption"
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: partnerships.featuredImage.title,
            }}
          />
        </div>
        <div className="main-intro row">
          <div
            className="intro-text col sans--regular col"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: partnerships.introduction }}
          />
          <div className="intro-divider col">
            <DividerBlock accentColor={partnerships.accentColor} />
          </div>
        </div>
        <div
          className="main-text col"
          dangerouslySetInnerHTML={{
            __html: partnerships.description,
          }}
        />
      </div>
      <div className="divider container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={partnerships.accentColor} />
        </div>
      </div>
    </Layout>
  );
};

Partnerships.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default Partnerships;

export const query = graphql`
  query partnershipsQuery {
    partnerships: datoCmsPartnership {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      backgroundColor
      accentColor {
        hex
      }
      title
      featuredImage {
        alt
        fluid(maxHeight: 500) {
          ...GatsbyDatoCmsSizes_noBase64
        }
        title
      }
      introduction
      description
    }
  }
`;
